import Button from "../../../Components/Dbutton";

const StepIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/device-two.png`;

const StepsThree = ({ onClose, onback }) => {
  return (
    <>
      <div className="d-model-icon">
        <img src={StepIcon} alt="" />
      </div>
      <div className="d-model-text">
        <h3>Desktop Gaming</h3>
        <p className="d-model-desc">
          Utilize a robust desktop setup to play the full version of the game.
          Earn Gold Coins through extensive gameplay, including player kills and
          NPC kills
        </p>
        <div className="flex">
          <Button
            variant="secondary-contained"
            text="Go to dashboard"
            onClick={onback}
            extraClasses="w-full"

          />
          <Button
            variant="primary-contained"
            text="finish"
            extraClasses="w-full"
            onClick={onClose}
          />
        </div>
      </div>
    </>
  );
};

export default StepsThree;
