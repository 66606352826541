import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const characterApi = createApi({
  reducerPath: "characterApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCharacters: builder.mutation({
      query: () => ({
        url: `/characters`,
        method: "GET",
      }),
    }),
    getCharacterById: builder.mutation({
      query: (id) => ({
        url: `/characters/${id}`,
        method: "GET",
      }),
    }),
    createCharactor: builder.mutation({
      query: (body) => ({
        url: `/characters`,
        method: "POST",
        body: body,
      }),
    }),
    updateCharactor: builder.mutation({
      query: ({ body, id }) => ({
        url: `/characters/${id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    deleteCharactor: builder.mutation({
      query: ({ body, id }) => ({
        url: `/characters/${id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    selectCharactor: builder.mutation({
      query: ({ charcterId, ...body }) => ({
        url: `/characters/select-character/${charcterId}`,
        method: "PATCH",
        body: body,
      }),
    }),
  }),
});

export const {
  useCreateCharactorMutation,
  useGetCharactersMutation,
  useGetCharacterByIdMutation,
  useDeleteCharactorMutation,
  useUpdateCharactorMutation,
  useSelectCharactorMutation,
} = characterApi;
