import React, { useRef, useEffect } from "react";

const OutsideClickHandler = ({ onOutsideClick, children, extraClasses }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onOutsideClick();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <div className={extraClasses} ref={wrapperRef}>
      {children}
    </div>
  );
};

export default OutsideClickHandler;
