import { useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import Dmodel from "../../../Components/Dmodel";
import { formatNumberWithCommas } from "../../../utils/helper";

const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;

const CompleteModel = ({ id, isActive, onClose, onCheck, data }) => {
  const [selectedTask, setSelectedTask] = useState();

  useEffect(() => {
    const currentItem = data?.find((item) => item._id === id);
    if (currentItem)
      setSelectedTask({ ...currentItem, ...currentItem.socialTask });
  }, [id, data]);

  return (
    <>
      <Dmodel onClose={onClose} isActive={isActive}>
        <div className="d-model-text task-model">
          <h3>{selectedTask?.title}</h3>

          <div
            className="d-icon"
            style={{
              background: selectedTask?.bgColor,
              boxShadow: `0 0 50px ${selectedTask?.bgColor}`,
            }}
          >
            <img src={selectedTask?.logo} alt="logo" />
          </div>
          <div className="icon-point">
            <h4>
              {"+"}
              {formatNumberWithCommas(Number(selectedTask?.coins || 0))}
            </h4>
            <img src={LogoIcon} alt="Logo-icon" />
          </div>
          <Button
            variant="secondary-contained"
            text={
              selectedTask?.type === "Watch-the-youtube-video"
                ? "Watch the video"
                : "Check it out"
            }
            extraClasses="full-contain mb2"
            onClick={() => {
              selectedTask?.type === "Friend" ? onClose() : onCheck(selectedTask);
            }}
          />
          {/* <Button
            variant="primary-contained"
            text="check"
            extraClasses="w-full light-btn"
          /> */}
        </div>
      </Dmodel>
    </>
  );
};

export default CompleteModel;
