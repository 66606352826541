import React from "react";
import DcardBox from "../../../../Components/DcardBox";
import { useSelector } from "react-redux";

const StepsThree = () => {
  const { heroImage, heroTitle } = useSelector((state) => state.task);

  return (
    <div className="d-model-text">
      <h3>your hero</h3>
      <h4>YOU’VE ENTERED THE LOTTERY! </h4>
      <p>
        Your hero has been successfully selected. You’re now entered into the
        lottery for a chance to win the Noble upgraded skin as an NFT after the
        airdrop. Good luck!
      </p>
      <div className="heros-image-slider selected-hero">
        <DcardBox
          variant="secondary-contained"
          extraClasses="d-image-slider-card"
        >
          <img src={heroImage} alt="Hero Img" />
          <h1>{heroTitle}</h1>
        </DcardBox>
      </div>
    </div>
  );
};

export default StepsThree;
