import { createSlice } from "@reduxjs/toolkit";
import { socialTaskApi } from "../../services/socialTask";
import { logout } from "./authSlice";

const initialState = {
  socialTasks: [],
  activeSocialTasks: [],
  completedSocialTasks: [],
};

const socialTaskSlice = createSlice({
  name: "socialTask",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(logout, (state) => {
        return initialState; // Reset state to initialState
      })
      .addMatcher(
        socialTaskApi.endpoints.getSocialTask.matchFulfilled,
        (state, { payload }) => {
          const { data } = payload;
          state.socialTasks = data;
        }
      )
      .addMatcher(
        socialTaskApi.endpoints.getUserSocialTask.matchFulfilled,
        (state, { payload }) => {
          const { data } = payload;
          state.socialTasks = data;
        }
      )
      .addMatcher(
        socialTaskApi.endpoints.getActiveSocialTask.matchFulfilled,
        (state, { payload }) => {
          const { data } = payload;
          state.activeSocialTasks = data;
        }
      )
      .addMatcher(
        socialTaskApi.endpoints.getCompletedSocialTask.matchFulfilled,
        (state, { payload }) => {
          const { data } = payload;
          state.completedSocialTasks = data;
        }
      );
  },
});

export default socialTaskSlice;
// export const { setChooseHero } = socialTaskSlice.actions;

// Selector to compute activeTaskCount
export const activeTaskCount = (state) =>
  state.socialTask.activeSocialTasks.length;
