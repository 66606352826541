import { createSlice } from "@reduxjs/toolkit";
import { characterApi } from "../../services/character";

const initialState = {
  characters: [],
  selectedCharacter: null,
};

const charaterSlice = createSlice({
  name: "character",
  initialState,
  reducers: {
    chooseYourHero: (state, action) => {
      state.selectedCharacter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      characterApi.endpoints.getCharacters.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        state.characters = data;
      }
    );
    builder.addMatcher(
      characterApi.endpoints.getCharacterById.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        state.selectedCharacter = data;
      }
    );
  },
});

export default charaterSlice;
export const { chooseYourHero } = charaterSlice.actions;
