import React, { useMemo } from "react";
import "./style.css";
import Dpagination from "../Dpagination";
import NavLink from "../Dlinks";

const SkeletonTable = ({ rowCount }) => {
  return (
    <tbody>
      {Array.from({ length: rowCount }).map((_, rowIndex) => (
        <tr className="skeleton-row" key={rowIndex}>
          <td className="first-col">
            <div className="skeleton-avatar"></div>
            <div className="skeleton-name"></div>
          </td>
          <td>
            <div className="skeleton-coin-text"></div>
          </td>
          <td>
            <div className="skeleton-coin-price"></div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const DynamicTable = ({
  data,
  columns,
  pagination,
  currentPage,
  setCurrentPage,
  isBackLink,
  searchQuery,
  totalCount,
  isLoading,
  onClick,
  onClickBack,
  isSkeleton,
  noData = "",
  key,
}) => {
  const filteredData = useMemo(() => {
    if (!searchQuery) return data;
    return data.filter((item) =>
      columns.some((column) =>
        item[column.accessor]
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, data, columns]);

  return (
    <div className="custom-table">
      <div className="table-bg">
        <table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.accessor} className="sortable">
                  {column.Header}
                </th>
              ))}
            </tr>
          </thead>
          {isLoading ? (
            <SkeletonTable rowCount={currentPage.pageSize} />
          ) : (
            <tbody>
              {filteredData?.length > 0 ? (
                filteredData?.map((row, index) => (
                  <>
                    <tr
                      key={key || index}
                      onClick={() => onClick && onClick(row)}
                    >
                      {columns.map((column) => (
                        <td key={column.accessor}>
                          {!column.cell && row[column.accessor]}
                          {column.cell && column.cell({ row })}
                        </td>
                      ))}
                    </tr>
                  </>
                ))
              ) : (
                <tr className="no-data">
                  <td colSpan={columns?.length}>
                    {noData}
                    {isBackLink && (
                      <NavLink text="Go Back " onClick={onClickBack} />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          )}
          {isSkeleton && filteredData?.length < 15 && (
            <SkeletonTable rowCount={currentPage.pageSize} />
          )}
        </table>
      </div>
      {pagination && (
        <Dpagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
          backText="Back"
          nextText="Next"
        />
      )}
    </div>
  );
};

export default DynamicTable;
