import React from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { playSound } from "../../utils/helper";

const VoltClawIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/charater.png`;
const PointImage = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/points-icon-new.svg`;
const CoinCellectSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/coin_collect_v1.wav`;

const DheroIconCard = ({ onhandlePoint }) => {
  const { user } = useSelector((state) => state.auth);
  const { currentPoints } = useSelector((state) => state.points);

  const handleClick = (event) => {
    createAnimatedImage(event);
    playSound(CoinCellectSound);
  };

  const createAnimatedImage = (event) => {
    const image = document.createElement("img");
    image.src = PointImage;
    image.className = "animated-image";

    const container = event.currentTarget;
    const rect = container.getBoundingClientRect();
    const x = event.clientX - rect.left - window.scrollX;
    const y = event.clientY - rect.top - window.scrollY;
    const randomWidth = Math.floor(Math.random() * (60 - 30 + 1)) + 70;

    image.style.position = "absolute";
    image.style.left = `${x - 30}px`;
    image.style.top = `${y - 30}px`;
    image.style.width = `${randomWidth}px`;

    container.appendChild(image);

    image.addEventListener("animationend", () => {
      image.remove();
    });
  };

  return (
    <div
      className="d-hero-icon"
      onClick={(e) => {
        if (user) {
          if (currentPoints > 3) {
            handleClick(e);
            onhandlePoint();
          }
        }
      }}
    >
      <img
        src={VoltClawIcon}
        alt=""
        className="d-hero-iconimg"
        draggable="false"
      />
    </div>
  );
};

export default DheroIconCard;
