import Explore from "../../../Components/Explore";
const LevelingVideo = `${process.env.REACT_APP_S3_BUCKET}/videos/levelingNew.mp4`;
const GohVideo = `${process.env.REACT_APP_S3_BUCKET}/videos/map-mastery.mp4`;
const MarketVideo = `${process.env.REACT_APP_S3_BUCKET}/videos/goldshopHigh.mp4`;
const LevelingBg = `${process.env.REACT_APP_S3_BUCKET}/Images/bg21.png`;
const LevelingPoster = `${process.env.REACT_APP_S3_BUCKET}/Images/bg21.png`;
const GohBg = `${process.env.REACT_APP_S3_BUCKET}/Images/explore-bg.png`;
const GohPoster = `${process.env.REACT_APP_S3_BUCKET}/Images/explore-bg.png`;
const MarketBg = `${process.env.REACT_APP_S3_BUCKET}/Images/about-map-bg.png`;
const MarketPoster = `${process.env.REACT_APP_S3_BUCKET}/Images/about-map-bg.png`;

const Leveling = {
  video: LevelingVideo,
  poster: LevelingPoster,
  autoPlay: true,
  title: "Leveling Up",
  tagline: "Experience leads to power.",
  desc: "As heroes accumulate specific amounts of experience, they advance in level, which allows them to unlock or enhance their abilities and improve their fundamental attributes. This growth is achieved by defeating enemy minions and heroes, aiding in a hero's defeat, and dismantling defensive structures.",
  backgroundImg: `url(${LevelingBg})`,
  className: "heros-videos",
};
const Goh = {
  video: GohVideo,
  poster: GohPoster,
  autoPlay: true,
  title: "Battle for Supremacy",
  tagline: "Strategy Defines Team Victory",
  desc: "Master tactical 5v5 battles by selecting the perfect hero and executing precise strategies. Coordinate with your team, capture objectives, and outsmart opponents. Victory awaits those with skill, strategy, and teamwork.",
  backgroundImg: `url(${GohBg})`,
  className: "PlayReverse heros-videos",
};
const Market = {
  video: MarketVideo,
  poster: MarketPoster,
  autoPlay: true,
  title: "Shop",
  tagline: "Enhancements with Goblore and Nectara",
  desc: "Spend your GOH COINS at our shop for crucial hero upgrades and items. This in-game currency, earned by overcoming challenges and enemies, is your ticket to boosting your hero's performance. Goblore and Nectara are ready to assist you in selecting the perfect enhancements and gear to ensure your hero is battle-ready.",
  backgroundImg: `url(${MarketBg})`,
  className: "heros-videos",
};

function AboutHeroDemo() {
  return (
    <section className="about-heros">
      <Explore {...Leveling} />
      <Explore {...Goh} />
      <Explore {...Market} />
    </section>
  );
}

export default AboutHeroDemo;
