import React, { useEffect, useState } from "react";
import "./style.css";
import DsearchBox from "../../../Components/DsearchBox";
import DdynamicTable from "../../../Components/DdynamicTable";
import { scoresColumns } from "../../../Constants";
import DtableModel from "./DtableModel";
import { useGetHighscroesMutation } from "../../../services/highscroes";
// import Loader from "../../../Components/Loader";

import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const GrapImage = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/table-border.png`;

const Dhighscores = () => {
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRowId, setIsRowId] = useState();
  const [currentPage, setCurrentPage] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [getHighscroes, { error, isLoading }] = useGetHighscroesMutation();
  const { highscroes, totalCount } = useSelector((state) => state.highscroes);

  useEffect(() => {
    const requestParams = {
      page: currentPage.pageNo,
      limit: currentPage.pageSize,
      search: searchQuery,
      sort: "gohCoins",
    };
    getHighscroes(requestParams);
  }, [
    currentPage.pageNo,
    currentPage.pageSize,
    getHighscroes,
    pathname,
    searchQuery,
  ]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage({
      ...currentPage,
      pageNo: 1,
    });
  };

  // If there is an error, display an error message
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <div className="d-top-left">
            <h2>highscores</h2>
          </div>
          <div className="d-top-right">
            <DsearchBox onChange={(e) => handleSearch(e)} intailvalue={searchQuery}/>
          </div>
        </div>

        <div className="scores-table">
          <img src={GrapImage} alt="grap" />
          <DdynamicTable
            data={highscroes}
            columns={scoresColumns}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchQuery={searchQuery}
            isLoading={isLoading}
            totalCount={totalCount}
            pagination={true}
            isBackLink="/dashboard"
            noData="Username not found"
            onClick={(record) => {
              setIsModalOpen(true);
              setIsRowId(record);
            }}
            onClickBack={() => {
              setSearchQuery("")
            }}
          />
          <img src={GrapImage} alt="grap" />
        </div>
      </div>
      <DtableModel
        player={isRowId}
        isActive={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setIsRowId();
        }}
      />
    </>
  );
};

export default Dhighscores;
