import { createSlice } from "@reduxjs/toolkit";
import { highscroesApi } from "../../services/highscroes";

const initialState = {
  highscroes: [],
  totalCount: 0,
};

const highscroesSlice = createSlice({
  name: "highscroes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      highscroesApi.endpoints.getHighscroes.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        state.highscroes = data.leaderboard;
        state.totalCount = data.totalPlayers;
      }
    );
  },
});

export default highscroesSlice;
