import { useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import Dmodel from "../../../Components/Dmodel";
import { currencyFormat, isClaimable } from "../../../utils/helper";

const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;

const ActiveModel = ({ id, isActive, onClose, onCheck, data }) => {
  const [selectedTask, setSelectedTask] = useState();

  useEffect(() => {
    const currentItem = data?.find((item) => item._id === id);
    if (currentItem) setSelectedTask(currentItem);
  }, [id, data]);

  const canBeClaimed = selectedTask
    ? isClaimable(selectedTask?.claimedAt)
    : false;


  return (
    <>
      <Dmodel onClose={onClose} isActive={isActive}>
        <div className="d-model-text task-model">
          <h3>{selectedTask?.socialTask?.title}</h3>
          {!canBeClaimed ? (
            <p>{selectedTask?.socialTask?.activeDescription}</p>
          ) : (
            <p>
              <span>Moderation complete!</span> <br />
              Your prize is now ready to be claimed.
            </p>
          )}
          <div
            className="d-icon"
            style={{
              background: selectedTask?.socialTask?.bgColor,
              boxShadow: `0 0 50px ${selectedTask?.socialTask?.bgColor}`,
            }}
          >
            <img src={selectedTask?.socialTask?.logo} alt="YouTube" />
          </div>
          <div className="icon-point">
            <h4>
              {"+"}
              {currencyFormat(Number(selectedTask?.socialTask?.coins))}
            </h4>
            <img src={LogoIcon} alt="Logo-icon" />
          </div>
          <Button
            variant="secondary-contained"
            text={
              data?.type === "Watch-the-youtube-video"
                ? "Watch the video"
                : "Check it out"
            }
            extraClasses="full-contain mb2"
            onClick={() => {
              window.open(selectedTask?.socialTask?.buttonLink, "_blank");
            }}
          />
          <Button
            variant="primary-contained"
            text="claim"
            extraClasses="w-full"
            disabled={!canBeClaimed}
            onClick={() => onCheck(selectedTask)}
          />
        </div>
      </Dmodel>
    </>
  );
};

export default ActiveModel;
