import React from "react";
const GohBg = `${process.env.REACT_APP_S3_BUCKET}/Images/explore-bg.png`;
const videoThumbnail = `${process.env.REACT_APP_S3_BUCKET}/Images/telegram-frame-new.svg`;

const data = [
  {
    title: "Complete Tasks",
    description:
      "Perform daily and weekly tasks directly from the Telegram app.",
  },
  {
    title: "Track Your Progress",
    description:
      "Your GOH COINS balance is updated in real-time and is synchronized with your in-game earnings.",
  },
  {
    title: "Social Engagement",
    description:
      "Participate in community events and social media activities through the app.",
  },
  {
    title: "Referral Rewards",
    description:
      "Generate your referral link in the app and share it with friends to earn additional GOH COINS.",
  },
  {
    title: "Leaderboard Access",
    description:
      "Check your ranking and see how you stack up against other players.",
  },
  {
    title: "Earn Rewards On-the-Go",
    description:
      "Use the Telegram Mini App to stay connected and earn GOH COINS even when you're not on a desktop.",
  },
];

const Telegram = () => {
  return (
    <div
      className="bg-img telegram-sec"
      style={{ backgroundImage: `url(${GohBg})` }}
    >
      <div className="container text-center">
        <div className="row">
          <div className="col-5">
            <div>
              <div className="play-image dash-bod-pic">
                <img src={videoThumbnail} alt="dashboard-img" />

                <a
                  href="https://t.me/guildofheroes_Bot"
                  className="btn mt-4 mb5"
                  rel="noreferrer"
                  target="_blank"
                >
                  PLAY NOW ON TELEGRAM
                </a>
              </div>
            </div>
          </div>
          <div className="col-7">
            <h2 className="h2head">How to Use the Telegram Mini App</h2>
            <p
              className="description"
              style={{ margin: "16px auto", maxWidth: "1000px" }}
            >
              Our Telegram Mini App allows you to earn GOH COIN even if you
              cannot play on a desktop. Both desktop and mobile users can take
              advantage of this app to stay engaged with the game and keep
              earning rewards.
            </p>

            <div className="ways-wrapper">
              {data.map((item, index) => (
                <div className="ways-Item w-50" key={index}>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
            {/* <button className="btn mt-4">play now</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Telegram;
