import React from "react";
import Explore from "../../../Components/Explore";
const MasterImg = `${process.env.REACT_APP_S3_BUCKET}/Images/mster-img.svg`;
const MarketImg = `${process.env.REACT_APP_S3_BUCKET}/Images/marketplace.jpg`;
const MarketInfo = `${process.env.REACT_APP_S3_BUCKET}/Images/market-img.png`;
const bgImg = `${process.env.REACT_APP_S3_BUCKET}/Images/bg31.png`;

const MarketPlace = {
  image: MarketInfo,
  title: "MARKETPLACE",
  tagline: "Discover the World of Heroes",
  desc: "Step into our secondary marketplace, a hub for players to trade and monetize their in-game success. Here, you can buy or sell rare skins and items, turning your gaming prowess into tangible rewards. Engage in a thriving economy where every item has value, driven by player supply and demand.",
  backgroundImg: `url(${MarketImg})`,
  className: "",
};

const PlayList = {
  image: MasterImg,
  title: "Master the Battle",
  tagline: "Ready To Become A Hero?",
  className: "PlayReverse",
  desc: "Discover the strategies, skills, and secrets to success in Guild of Heroes. Learn everything from basic gameplay to advanced tactics in our comprehensive guide.",
  backgroundImg: `url(${bgImg})`,
  buttonText: "LEARN HOW TO PLAY",
};

function PlayReverse() {
  return (
    <>
      <Explore {...MarketPlace} />
      <Explore {...PlayList} />
    </>
  );
}

export default PlayReverse;
