import React, { useEffect, useState } from "react";
import "./style.css";
import { airdropData } from "../../../Constants/list";
import DairdropModel from "./airdropModel";
import DloginModal from "../../../Components/DloginModal";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import NavLink from "../../../Components/Dlinks";
import DcardBox from "../../../Components/DcardBox";
import { currencyFormat } from "../../../utils/helper";
const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/logoIconNew.svg`;

const DairDrop = () => {
  const [isDataId, setIsDataId] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isWelcomeModal, setIsWelcomeModal] = useState(false);
  const pathname = useLocation();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (pathname.search) {
      const isOpen = pathname.search.split("=")[1];
      setIsModelOpen(isOpen);
    }
  }, [pathname]);

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <div className="d-top-left">
            <h2>Airdrop</h2>
          </div>
        </div>
        <div className="airdrop-banner">
          <div>
            <h2>participate in the GOH token airdrop</h2>
            <p>
              Join the exciting GOH token airdrop by collecting GOH COINS. Earn
              GOH COINS by engaging with our platform and completing tasks. The
              more active you are, the more GOH COINS you can collect
            </p>
          </div>
          <div>
            <img src={LogoIcon} alt="Logo-icon" />
          </div>
        </div>

        <div className="d-task-list">
          {airdropData.map((item, index) => {
            return (
              <DcardBox
                variant="secondary-contained"
                extraClasses={`d-task-card`}
              >
                <div className="icon-point">
                  {item.status && <div className={`dot`}></div>}

                  <>
                    {item.count && (
                      <div>
                        <span>
                          {"+"}
                          {item?.reward
                            ? currencyFormat(Number(item?.reward))
                            : item.count}
                        </span>
                        <img
                          src={LogoIcon}
                          alt="Logo-icon"
                          className="tool-icon"
                        />
                      </div>
                    )}
                  </>
                </div>

                <div
                  className="d-icon"
                  style={{
                    background: item.color,
                    boxShadow: `0 0 50px ${item.color}`,
                  }}
                >
                  <img src={item.icon} alt="Task Icon" />
                </div>

                <div className="d-task-content">
                  <h3 className="hrad-text">{item.title}</h3>
                  <NavLink
                    text={item.linkText}
                    link={item.btnLink}
                    rightIcon
                    onClick={() => {
                      if (!item.target) {
                        setIsDataId(item.id);
                        user ? setIsModelOpen(true) : setIsWelcomeModal(true);
                      } else {
                        window.open(item.btnLink);
                      }
                    }}
                    target={item.target}
                  />
                </div>
              </DcardBox>
             
            );
          })}
        </div>
      </div>
      <DairdropModel
        id={isDataId}
        onClose={() => {
          setIsModelOpen(false);
          setIsDataId();
        }}
        isActive={isModelOpen}
      />
      <DloginModal
        onClose={() => {
          setIsWelcomeModal(false);
        }}
        isActive={isWelcomeModal}
      />
    </>
  );
};

export default DairDrop;
