import React, { useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import "./style.css";
import DTaskCard from "../../../Components/DtaskCard";
// import { DailyTaskList } from "../../../Constants/list";
import DdailyTaskModel from "./DtaskModel";
import DloginModal from "../../../Components/DloginModal";
import { useSelector } from "react-redux";
import { useGetDailyTaskMutation } from "../../../services/dailyTask";
import { useLocation } from "react-router-dom";
// import Loader from "../../../Components/Loader";
import DcardBox from "../../../Components/DcardBox";
import NavLink from "../../../Components/Dlinks";
import Dtoast from "../../../Components/Dtoast";
import { DailyTaskList } from "../../../Constants/list";
import DailyTaskModels from "../dailyTaskModels";
import { currencyFormat, playSound } from "../../../utils/helper";
const DDailyTaskLogo = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;
const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;
const Succescheck = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/succescheck.svg`;
const RewardClaimSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/Reward_Collection_v4.wav`;

const DailyTask = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isWelcomeModal, setIsWelcomeModal] = useState(false);
  const [isStepsModal, setIsStepsModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [coin, setCoin] = useState(0);
  const { dailyTask } = useSelector((state) => state.dailyTask);
  const [isDataId, setIsDataId] = useState("");
  const pathname = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [getDailyTask, { error, isLoading, onSuccess }] =
    useGetDailyTaskMutation();

  useEffect(() => {
    if (user) {
      getDailyTask(user?._id);
    }
  }, [getDailyTask, user, pathname, onSuccess]);

  // If there is an error, display an error message
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <div className="d-top-left">
            <h2>daily tasks</h2>
            <p>
              Accumulate coins by logging into the game daily without missing a
              day
            </p>
          </div>
          <div className="d-top-right">
            <Button
              variant="secondary-contained"
              text="how it works"
              onClick={() => setIsStepsModal(true)}
            />
          </div>
        </div>

        {isLoading ? (
          <div className="load-full">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div className="d-task-list">
              {user ? (
                <>
                  {dailyTask?.map((item, index) => {
                    return (
                      <DcardBox
                        variant="secondary-contained"
                        extraClasses={`d-task-card ${
                          !item?.isActive && !item.completed
                            ? "light-opacity-disable"
                            : ""
                        }`}
                        key={index}
                      >
                        <div className="icon-point">
                          {/* {isStatusShow && data.status && <div className="dot"></div>} */}
                          {item?.reward && (
                            <div>
                              <span>
                                {"+"}
                                {currencyFormat(item?.reward)}
                              </span>
                              <img
                                src={LogoIcon}
                                alt="Logo-icon"
                                className="tool-icon"
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className="d-icon"
                          style={{
                            background: "#E4B81D",
                            boxShadow: `0 0 50px #E4B81D`,
                          }}
                        >
                          <img src={DDailyTaskLogo} alt="icon" />
                        </div>
                        <div className="d-task-content">
                          <h3 className="hrad-text">day {item?.day}</h3>
                          {!item.completed ? (
                            <NavLink
                              text="claim"
                              rightIcon
                              onClick={() => {
                                setIsModelOpen(true);
                                setIsDataId(item);
                              }}
                            />
                          ) : (
                            <img
                              src={Succescheck}
                              alt="Succescheck-icon"
                              className="success-check-icon"
                            />
                          )}
                        </div>
                      </DcardBox>
                    );
                  })}
                </>
              ) : (
                <>
                  {DailyTaskList?.map((item, index) => {
                    return (
                      <DTaskCard
                        data={item}
                        key={index}
                        extraClasses="light-opacity"
                        onClick={() => {
                          setIsDataId(item);
                          setIsWelcomeModal(true);
                          setCoin("");
                        }}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </>
        )}
      </div>

      <DdailyTaskModel
        data={isDataId}
        onClose={() => {
          setIsModelOpen(false);
          setIsDataId();
        }}
        onSuccess={(reward) => {
          console.log(reward, "reward");
          getDailyTask(user?._id);
          playSound(RewardClaimSound);
          setIsSuccess(true);
          setCoin(reward);
        }}
        isActive={isModelOpen}
      />

      <DailyTaskModels
        isActive={isStepsModal}
        onClose={() => setIsStepsModal(false)}
        activeModel="stepone"
      />

      <DloginModal
        onClose={() => {
          setIsWelcomeModal(false);
        }}
        isActive={isWelcomeModal}
      />
      {isSuccess && (
        <Dtoast
          setIsSuccess={setIsSuccess}
          isSuccess={isSuccess}
          message={`You’ve earned ${coin} coins. Keep going to earn more rewards!`}
        />
      )}
    </>
  );
};

export default DailyTask;
