export function formatText(text) {
  return (
    text
      // Add space before capital letters
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // Add space before numbers
      .replace(/([a-zA-Z])([0-9])/g, "$1 $2")
      // Add space after numbers
      .replace(/([0-9])([a-zA-Z])/g, "$1 $2")
  );
}

export function formatEventType(text) {
  return text.replace(/_/g, " ");
}
