import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const socialTaskApi = createApi({
  reducerPath: "socialTaskApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getSocialTask: builder.mutation({
      query: () => ({
        url: `/social-tasks`,
        method: "GET",
      }),
    }),
    getUserSocialTask: builder.mutation({
      query: (userId) => ({
        url: `/social-tasks/available/${userId}`,
        method: "GET",
      }),
    }),
    getActiveSocialTask: builder.mutation({
      query: (userId) => ({
        url: `/user-tasks/${userId}?status=active`,
        method: "GET",
      }),
    }),
    getCompletedSocialTask: builder.mutation({
      query: (userId) => ({
        url: `/user-tasks/${userId}?status=claimed`,
        method: "GET",
      }),
    }),
    getSocialTaskById: builder.mutation({
      query: (id) => ({
        url: `/social-tasks/${id}`,
        method: "GET",
      }),
    }),
    activateSocialTask: builder.mutation({
      query: (body) => ({
        url: `/user-tasks`,
        method: "POST",
        body: body,
      }),
    }),
    claimSocialTask: builder.mutation({
      query: ({ body, taskId }) => ({
        url: `/user-tasks/${taskId}`,
        method: "PATCH",
        body: body,
      }),
    }),
    // createSocialTask: builder.mutation({
    //   query: (body) => ({
    //     url: `/social-tasks/generate-social-task`,
    //     method: "POST",
    //     body: body,
    //   }),
    // }),
    // updateSocialTask: builder.mutation({
    //   query: ({ body, id }) => ({
    //     url: `/social-tasks/${id}`,
    //     method: "PATCH",
    //     body: body,
    //   }),
    // }),
    // deleteSocialTask: builder.mutation({
    //   query: ({ body, id }) => ({
    //     url: `/social-tasks//${id}`,
    //     method: "PATCH",
    //     body: body,
    //   }),
    // }),
  }),
});

export const {
  // useCreateSocialTaskMutation,
  // useDeleteSocialTaskMutation,
  // useUpdateSocialTaskMutation,
  useGetSocialTaskByIdMutation,
  useGetSocialTaskMutation,
  useActivateSocialTaskMutation,
  useClaimSocialTaskMutation,
  useGetActiveSocialTaskMutation,
  useGetCompletedSocialTaskMutation,
  useGetUserSocialTaskMutation
} = socialTaskApi;
