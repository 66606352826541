import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconList from "../socialIcon";
import { navMenu, soicalList } from "../../Constants";
import ProfileBtn from "../ProfileBtn";
import { walletList } from "../../Constants/wallets";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { DownOutlined } from "@ant-design/icons";
import { Divider, Dropdown, Space } from "antd";
import { useSelector } from "react-redux";
import { useAuth } from "../../Context/AuthContext";
import CountUpAnimation from "../CountUpAnimation";
const CloseIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/closeIcon.svg`;
const WalletIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/wallet.svg`;
const ProfileIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/profile-icon.svg`;

function Sidebar({ active, handleClick, isOpenSubMenu, setIsOpenSubMenu }) {
  const [open, setOpen] = useState(false);
  const wmt = useWeb3Modal();
  const handleClose = () => setOpen(false);

  const { walletAddress } = useAuth();
  const { user, playFabInfo } = useSelector((state) => state.auth);

  const contentStyle = {
    backgroundColor: "#000",
    borderRadius: 20,
    zIndex: 10000,
    marginTop: 10,
  };
  // const menuStyle = {
  //   boxShadow: "none",
  //   background: "#191C1F",
  // };

  return (
    <section className={`Sidebar-menu ${active ? "Open-menu" : ""}`}>
      <img
        className="close-btn"
        onClick={handleClick}
        src={CloseIcon}
        alt="Close Icon"
      />
      <div className="row">
        <div className="leftdiv">
          <ul className="nav">
            {navMenu &&
              navMenu?.length &&
              navMenu?.map((item, index) => {
                if (item.title === "Airdrop" && !user) {
                  return null; // Don't render the "Airdrop" menu if user is not logged in
                }

                return (
                  <li
                    key={index}
                    onClick={() => !item.subMenu && handleClick()}
                  >
                    {!item.subMenu ? (
                      <Link to={item.link}>{item.title}</Link>
                    ) : (
                      <Dropdown
                        style={{ margin: "100px" }}
                        menu={{ items: item?.subMenu }}
                        trigger={["click"]}
                        dropdownRender={(menu) => (
                          <div style={contentStyle} onClick={handleClick}>
                            <Divider
                              style={{
                                margin: 0,
                              }}
                            />
                          </div>
                        )}
                      >
                        <Space
                          style={{
                            cursor: "pointer",
                            fontSize: 20,
                            letterSpacing: "0.05em",
                            color: "#ede5ce",
                            textTransform: "uppercase",
                          }}
                        >
                          {item.title}
                          <DownOutlined />
                        </Space>
                      </Dropdown>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="rightdiv">
          <IconList data={soicalList} />
          <div className="log-btn-container">
            {!user ? (
              <>
                <a
                  href={process.env.REACT_APP_LOGIN_URL}
                  onClick={handleClick}
                  className="log-btn"
                >
                  LOG IN
                </a>
                <a
                  href="https://guildofheroes.gitbook.io"
                  target="_blank"
                  rel="noreferrer"
                  className="btn"
                >
                  download now{" "}
                </a>
              </>
            ) : (
              <>
                <div class="rakec mb-10">
                  <img src={ProfileIcon} alt="profile-icon " />
                  <div class="lerak">
                    <p>GOH COINS EARNED</p>
                    <span>
                      <CountUpAnimation amount={user?.gohCoins || "--"} />
                    </span>
                  </div>
                </div>
                <ProfileBtn
                  handleClick={handleClick}
                  username={
                    playFabInfo?.AccountInfo.GoogleInfo
                      ? playFabInfo?.AccountInfo.GoogleInfo.GoogleName
                      : playFabInfo?.AccountInfo.FacebookInfo
                      ? playFabInfo?.AccountInfo.FacebookInfo.FullName
                      : playFabInfo?.AccountInfo.Username
                  }
                />
                {/* <span className="btn wallet-btn" onClick={() => wmt.open()}>
                  {walletAddress ? (
                    walletAddress?.slice(0, 5) +
                    "..." +
                    walletAddress?.slice(walletAddress.length - 3)
                  ) : (
                    <img src={WalletIcon} alt="wallet Icon" />
                  )}
                </span> */}
                <div id="myModal" className={open ? "modal open" : "modal"}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h2 className="h2head">Connect to Wallet</h2>
                      <img
                        alt="close"
                        className="close"
                        src={CloseIcon}
                        onClick={handleClose}
                      />
                    </div>
                    <div className="modal-list">
                      {walletList.map((wallet) => (
                        <div className="modal-item" key={wallet.id}>
                          <img
                            className="item-icon"
                            src={wallet.image}
                            alt=""
                          />
                          <span>{wallet.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sidebar;
