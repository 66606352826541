import "./style.css";
import StepsOne from "./stepOne";
import StepsTwo from "./stepTwo";
import StepsThree from "./stepThree";
import Dmodel from "../../../Components/Dmodel";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DailyTaskModels = ({ activeModel, isActive, onClose }) => {
  const [currentPopup, setCurrentPopup] = useState(activeModel);
  const navigate = useNavigate();

  useEffect(() => {
    if (activeModel) {
      setCurrentPopup(activeModel);
    }
  }, [activeModel]);

  const modals = {
    stepone: (
      <StepsOne
        onNext={() => setCurrentPopup("steptwo")}
        onback={() => navigate("/dashboard")}
      />
    ),
    steptwo: <StepsTwo onNext={() => setCurrentPopup("Stepsthree")} />,
    Stepsthree: (
      <StepsThree
        onClose={() => {
          onClose();
          setCurrentPopup("stepone");
        }}
        onback={() => navigate("/dashboard")}
      />
    ),
  };

  const steps = Object.keys(modals);
  const currentIndex = steps.indexOf(currentPopup);

  return (
    <Dmodel
      steps={steps.length}
      isCurrentStep={currentIndex}
      onClose={() => {
        onClose();
        setCurrentPopup("stepone");
      }}
      isActive={isActive}
      extraClasses="combat-drive step-modal"
    >
      {modals[currentPopup]}
    </Dmodel>
  );
};

export default DailyTaskModels;
