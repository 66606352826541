import { useMemo } from "react";
import "./style.css";
import React from "react";

const DcardBox = ({ variant, extraClasses, children, style, ...rest }) => {
  const getVariantClasses = useMemo(() => {
    switch (variant) {
      case "primary-contained":
        return "d-bg-primary-card";
      case "secondary-contained":
        return "d-bg-secondary-card";
      default:
        return "";
    }
  }, [variant]);

  return (
    <div
      className={`${getVariantClasses} ${extraClasses} d-card`}
      {...rest}
      style={style}
    >
      {children}
    </div>
  );
};

export default DcardBox;
