import React, { useEffect, useState } from "react";
import "./style.css";
import DrewardFeedTable from "./DrewardFeedTable";
import DcardBox from "../../../Components/DcardBox";
import DpointBar from "../../../Components/DpointsBar";
import DheroIconCard from "../../../Components/DheroIconCard";
import { useGetClaimCharacterRewardMutation } from "../../../services/characterReward";
import { useDispatch, useSelector } from "react-redux";
import { setUserCoins } from "../../../store/slices/authSlice";
import { formatNumberWithCommasForHero } from "../../../utils/helper";
import { decrementPoints } from "../../../store/slices/pointSlice";
import { createEvent, eventsTypes } from "../../../utils/event";
const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;

const Dhome = () => {
  const [getClaimCharacterReward, { error }] =
    useGetClaimCharacterRewardMutation();
  const { user } = useSelector((state) => state.auth);
  const [hasClicked, setHasClicked] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const { currentPoints, totalPoints } = useSelector((state) => state.points);
  const dispatch = useDispatch();

  const handlePointClick = () => {
    setHasClicked(true);
    setClickCount((prevCount) => prevCount + 1);
    dispatch(decrementPoints(3));
    dispatch(setUserCoins(3));
  };

  useEffect(() => {
    if (hasClicked) {
      const timeout = setTimeout(() => {
        const form = {
          userId: user?._id,
          tapCount: clickCount,
        };
        getClaimCharacterReward(form);
        createEvent(eventsTypes.Tap_on_Character, form.tapCount * 3);
        setHasClicked(false);
        setClickCount(0);
      }, 1000);

      return () => clearInterval(timeout);
    }
  }, [hasClicked, clickCount, getClaimCharacterReward, user?._id]);

  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="d-content flex home-table-flex">
      <div className="d-hero-col">
        <DcardBox variant="primary-contained" extraClasses="d-home-table">
          <div className="d-top-bar">
            <h2>Game</h2>
            {/* <Button
              variant="secondary-contained"
              text="Boost"
              extraClasses="boot-btn"
              icon={RoketIcon}
              disabled={!user ? true : false}
            /> */}
          </div>
          <div className="icon-point total-count">
            <img src={LogoIcon} alt="Logo-icon" />
            <p className="d-gradient-text">
              {user ? formatNumberWithCommasForHero(user?.gohCoins) : "0.00"}
            </p>
          </div>
          <DheroIconCard
            onhandlePoint={() => {
              if (currentPoints > 3) {
                handlePointClick();
              }
            }}
          />
          <DpointBar value={currentPoints} total={totalPoints} />
        </DcardBox>
      </div>
      <div className="d-table-col">
        <DcardBox variant="primary-contained" extraClasses="d-home-table">
          <div className="d-top-bar">
            <h2>Reward feed</h2>
          </div>
          <DrewardFeedTable />
        </DcardBox>
      </div>
    </div>
  );
};

export default Dhome;
