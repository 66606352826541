import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Dbutton";
import DModel from "../../../Components/Dmodel";
import { useGetClaimDailyTaskMutation } from "../../../services/dailyTask";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../utils/helper";
import { setUserCoins } from "../../../store/slices/authSlice";
import { createEvent, eventsTypes } from "../../../utils/event";
// import Dtoast from "../../../Components/Dtoast";
const DDailyTaskLogo = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;

const DdailyTaskModel = ({ data, isActive, onClose, onSuccess }) => {
  const [getClaimDailyTask, { isLoading }] = useGetClaimDailyTaskMutation();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleClaim = async (taskId, reward) => {

    const form = {
      userId: user?._id,
      taskId: taskId,
    };
    try {
      const result = await getClaimDailyTask(form).unwrap();
      if (result) {
        dispatch(setUserCoins(reward * 1));
        onSuccess?.(reward);
        createEvent(eventsTypes.Claimed_Daily_Reward, reward)
        onClose();
      }
    } catch (error) {
        toast.error(getErrorMessage(error?.data));
      }
    };

  return (
    <>
      <DModel
        onClose={() => {
          onClose();
        }}
        isActive={isActive}
        extraClasses="daily-modal"
      >
        <div className="d-model-icon goh-circle">
          <img src={DDailyTaskLogo} alt="icon" />
        </div>
        <div className="d-model-text">
          <h3 style={{marginBottom:20}}>day{data?.day }</h3>
          {data?.reward && <h3 style={{marginBottom:20}}>{data?.reward}</h3>}
          <Button
            variant="primary-contained"
            text="get"
            extraClasses="w-full"
            disabled={isLoading}
            onClick={() => handleClaim(data?._id, data?.reward)}
          />
        </div>
      </DModel>
    </>
  );
};

export default DdailyTaskModel;
