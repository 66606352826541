import React, { useState } from "react";
import DSwitch from "../Dswitch";
import OutsideClickHandler from "../OutsideClickHandler";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import { toggleVideo } from "../../store/slices/videoSlice";
import { playSound } from "../../utils/helper";
import { truncateURL } from "../../utils/common";
const ArrowDown = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/arrow-down.svg`;
const WalletIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/wallet-icon-orange.svg`;
const LogOutIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/logout-fill.svg`;
const MenuChangeSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/button_UI_click.wav`;

const Dprofile = ({ data, extraClasses, isdropdown }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [animating, setAnimating] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleVideoClick = () => {
    dispatch(toggleVideo());
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    playSound(MenuChangeSound);
  };

  const toggleAnimation = () => {
    setAnimating(false);
    setActive(!active);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => setIsDropdownOpen(false)}
        extraClasses="out-side-closer"
      >
        <div
          className={`d-profile-card ${extraClasses ? extraClasses : ""}`}
          onClick={toggleDropdown}
        >
          <div className="d-profile-image">
            <img src={data.image} alt="profile-img" />

            {data?.count?.toString() && (
              <div className={`${isdropdown && "rank-tooltip"} d-tool-tip`}>
                {isdropdown && <h4>Rank</h4>}
                {data?.count}
              </div>
            )}
          </div>

          <div className="d-profile-content">
            {data.title && <h4>{data.title}</h4>}
            {data.id && <p>{truncateURL(data.id, 12)}</p>}
            {data.status && <div className="isOnline">{data.status}</div>}
          </div>
          {data.subItem && <img src={ArrowDown} alt="arrow-down" />}
        </div>
        {isdropdown && isDropdownOpen && (
          <div className="pro-card-wrapper">
            <ul>
              <li onClick={() => navigate("/airdrop?wallet=true")}>
                <img src={WalletIcon} alt="" />
                connect wallet
              </li>
              <li
                onClick={() => {
                  handleVideoClick();
                  toggleAnimation();
                }}
              >
                <DSwitch
                  active={active}
                  animating={animating}
                  onClick={() => {
                    handleVideoClick();
                    toggleAnimation();
                  }}
                />
                {active ? "EFFECTS TURN ON" : " EFFECTS TURN OFF"}
              </li>
              <li onClick={handleLogout}>
                <img src={LogOutIcon} alt="" />
                Logout
              </li>
            </ul>
          </div>
        )}
      </OutsideClickHandler>
    </>
  );
};

export default Dprofile;
