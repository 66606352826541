import Button from "../../../Components/Dbutton";
import NavLink from "../../../Components/Dlinks";

const StepIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/device-three.png`;

const StepsTwo = ({ onNext }) => {
  return (
    <>
      <div className="d-model-icon">
        <img src={StepIcon} alt="" />
      </div>
      <div className="d-model-text tele-modal">
        <h3>Telegram App Earnings</h3>
        <p className="d-model-desc"> 
          Complete similar tasks via the Telegram mini-app. Ideal for earning
          GOH Coins when you're away from your computer, keeping you engaged
          wherever you go
        </p>
        <div className="flex">

        <Button
            variant="secondary-contained"
            text="Go to Telegram"
            extraClasses="w-full"
            onClick={()=> window.open('https://web.telegram.org/k/')}
          />
        <Button
          variant="primary-contained"
          text="Next"
          extraClasses="w-full"
          onClick={onNext}
        />
      </div>
      </div>
    </>
  );
};

export default StepsTwo;
