import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import Button from "../../../Components/Dbutton";
import DtaskCard from "../../../Components/DtaskCard";
import ActiveModel from "./activeModel";
import DloginModal from "../../../Components/DloginModal";
import { useDispatch, useSelector } from "react-redux";
import {} from "../../../services/tasks";
import { toast } from "react-toastify";
import Dtoast from "../../../Components/Dtoast";
import { setUserCoins } from "../../../store/slices/authSlice";
import DailyTaskModels from "../dailyTaskModels";
import { createEvent, getEventType } from "../../../utils/event";
import { playSound } from "../../../utils/helper";
import {
  useClaimSocialTaskMutation,
  useGetActiveSocialTaskMutation,
} from "../../../services/socialTask";
const RewardClaimSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/Reward_Collection_v4.wav`;

const DactiveTask = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isStepsModal, setIsStepsModal] = useState(false);
  const [coins, setCoins] = useState(0);
  const [isDataId, setIsDataId] = useState();
  const { user } = useSelector((state) => state.auth);
  const { activeSocialTasks } = useSelector((state) => state.socialTask);
  const dispatch = useDispatch();

  const [getAllActiveTasks, { isLoading }] = useGetActiveSocialTaskMutation();
  const [claimUserTask] = useClaimSocialTaskMutation();

  const fetchUserTask = useCallback(
    async (id) => {
      try {
        await getAllActiveTasks(id).unwrap();
      } catch (err) {
        toast.error(err?.message);
      }
    },
    [getAllActiveTasks]
  );

  const updateUserTask = async (taskId) => {
    const body = {
      claimedAt: new Date(),
      status: "claimed",
    };
    try {
      const result = await claimUserTask({ body, taskId }).unwrap();
      console.log("result", result);
      if (result.data) {
        setShowToast(true);
        playSound(RewardClaimSound);

        setCoins(result.data?.socialTask?.coins);
        createEvent(getEventType(result.data?.socialTask?.type), result.data?.socialTask?.coins);
        dispatch(setUserCoins(result.data?.socialTask?.coins));
        setIsModelOpen(false);
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserTask(user?._id);
    }
  }, [user, fetchUserTask]);

  const filteredTasks = activeSocialTasks || [];

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <h2>active tasks</h2>
          <Button
            variant="secondary-contained"
            text="how it works"
            onClick={() => setIsStepsModal(true)}
          />
        </div>

        {filteredTasks.length === 0 && isLoading && (
          <div className="load-full">
            <div className="loader"></div>
          </div>
        )}
        {filteredTasks.length === 0 && !isLoading ? (
          <div>You don't have any active tasks yet</div>
        ) : null}

        <div className="d-task-list">
          {(user ? filteredTasks : [])?.map((item, index) => {
            return (
              <DtaskCard
                data={{ ...item, ...item.socialTask }}
                key={index}
                onClick={() => {
                  setIsModelOpen(true);
                  setIsDataId(item?._id);
                }}
                isStatusShow
              />
            );
          })}
        </div>
      </div>
      {user ? (
        <ActiveModel
          onClose={() => {
            setIsModelOpen(false);
            setIsDataId();
          }}
          isActive={isModelOpen}
          data={activeSocialTasks}
          id={isDataId}
          onCheck={(task) => updateUserTask(task?._id)}
        />
      ) : (
        <DloginModal
          onClose={() => {
            setIsModelOpen(false);
          }}
          isActive={isModelOpen}
        />
      )}

      <DailyTaskModels
        isActive={isStepsModal}
        onClose={() => setIsStepsModal(false)}
        activeModel="stepone"
      />

      {showToast && (
        <Dtoast
          setIsSuccess={setShowToast}
          isSuccess={showToast}
          message={`You recieved ${coins} GOH Gold! Keep collecting to climb the ranks!`}
        />
      )}
    </>
  );
};

export default DactiveTask;
