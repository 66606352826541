import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import DdynamicTable from "../../../Components/DdynamicTable";
import { rewardFeedColumns } from "../../../Constants";
import { useSelector } from "react-redux";

import { useGetHighscroesMutation } from "../../../services/highscroes";

const DrewardFeedTable = () => {
  const [currentPage, setCurrentPage] = useState({
    pageNo: 1,
    pageSize: 13,
  });
  const { events } = useSelector((state) => state.events);
  const [isLoading, setIsLoading] = useState(false);
  const [getHighscroes] = useGetHighscroesMutation();

  useEffect(() => {
    getHighscroes({});
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (events.length === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [events]);

  return (
    <>
      {useMemo(() => {
        return (
          <DdynamicTable
            data={events}
            columns={rewardFeedColumns}
            key={events.map((item) => item.userName).join(",")}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isBackLink=""
            noData="No events available yet"
            isLoading={isLoading}
            isSkeleton
          />
        );
      }, [currentPage, isLoading, events])}
    </>
  );
};

export default DrewardFeedTable;
