export function truncateURL(url, maxLength) {
  if (url.length <= maxLength) {
    return url;
  }

  const partLength = Math.floor((maxLength - 3) / 2);
  const firstPart = url.substring(0, partLength);
  const lastPart = url.substring(url.length - partLength, url.length);

  return `${firstPart}...${lastPart}`;
}

export function isNew(createdAt) {
  const oneDayInMilliseconds = 1 * 24 * 60 * 60 * 1000; // 1 day in milliseconds
  const createdAtDate = new Date(createdAt); // Convert createdAt string to a Date object
  const currentDate = new Date(); // Get the current date

  // Check if the difference between current date and createdAt date is less than 1 day
  return (currentDate - createdAtDate) < oneDayInMilliseconds;
}