import "./style.css";
import StepsOne from "./stepOne";
import StepsTwo from "./stepTwo";
import Dmodel from "../../../../Components/Dmodel";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StepsThree from "./stepThree";

const TaskModels = ({
  id,
  isActive,
  onClose,
  activeModelName,
  onJoin,
  onCheck,
  onConfirm,
}) => {
  const [currentPopup, setCurrentPopup] = useState(activeModelName);
  const [isData, setIsData] = useState();
  const { socialTasks } = useSelector((state) => state.socialTask);

  useEffect(() => {
    if (activeModelName) {
      setCurrentPopup(activeModelName);
    }
    const currentItem = socialTasks.find((item) => item._id === id);

    setIsData(currentItem);
  }, [activeModelName, id, socialTasks]);

  const modals = {
    stepone: <StepsOne data={isData} onJoin={onJoin} onCheck={onCheck} />,
    steptwo: <StepsTwo data={isData} onConfirm={onConfirm} />,
    stepsthree: <StepsThree data={isData} onConfirm={onConfirm} />,
  };

  return (
    <Dmodel onClose={onClose} isActive={isActive}>
      {modals[currentPopup]}
    </Dmodel>
  );
};

export default TaskModels;
