import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Dmodel from "../../../Components/Dmodel";
import { useGetCharacterByIdMutation } from "../../../services/character";
import DcardBox from "../../../Components/DcardBox";

const HeroModel = ({ onClose, isActive }) => {
  const { user } = useSelector((state) => state.auth);
  const { selectedCharacter } = useSelector((state) => state.character);

  const [getCharacterById, { isLoading }] = useGetCharacterByIdMutation();

  useEffect(() => {
    if (user?.character) {
      getCharacterById(user.character);
    }
  }, [user, getCharacterById]);

  return (
    <Dmodel onClose={onClose} isActive={isActive}>
      <div className="d-model-text">
        <h3>your hero</h3>
        <h4>YOU’VE ENTERED THE LOTTERY! </h4>
        <p>
          Your hero has been successfully selected. You’re now entered into the
          lottery for a chance to win the Noble upgraded skin as an NFT after
          the airdrop. Good luck!
        </p>
        {isLoading ? (
          <div className="load-full" style={{ height: "300px", width: "100%" }}>
            <div className="loader"></div>
          </div>
        ) : (
          <div className="heros-image-slider selected-hero">
            <DcardBox
              variant="secondary-contained"
              extraClasses="d-image-slider-card"
            >
              <img src={selectedCharacter?.avatar} alt="Hero Img" />
              <h1>{selectedCharacter?.name}</h1>
            </DcardBox>
          </div>
        )}
      </div>
    </Dmodel>
  );
};

export default HeroModel;
