import "./style.css";
import React from "react";
import DcardBox from "../DcardBox";
import NavLink from "../Dlinks";
import { currencyFormat } from "../../utils/helper";

const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;

const DtaskCard = ({
  isNew,
  data,
  extraClasses,
  onClick,
  isStatusShow,
  style,
  isHeroSelected,
  ...rest
}) => {
  const btnText =
    (data?.status === "active" && "Claim Reward") ||
    (data?.status === "claimed" && "Check it out") ||
    "Start the task";
  return (
    <DcardBox
      variant="secondary-contained"
      extraClasses={`d-task-card ${extraClasses ? extraClasses : ""}`}
      {...rest}
      style={{ ...style }}
    >
      <div className="icon-point">
        {isNew ? (
          <div
            className="d-tool-tip"
            style={{ padding: "2px 8px" }}
          >
            <div className="d-gradient-text">NEW</div>
          </div>
        ):<div />}
        {isStatusShow && data.status && (
          <div
            className={`dot ${
              data?.status === "claimed" ? "completed" : "active"
            }`}
          ></div>
        )}
        {!isHeroSelected && (
          <>
            <div>
              <span>
                {"+"}
                {currencyFormat(Number(data?.coins))}
              </span>
              <img src={LogoIcon} alt="Logo-icon" className="tool-icon" />
            </div>
          </>
        )}
      </div>

      {!isHeroSelected && (
        <div
          className="d-icon"
          style={{
            background: data.bgColor,
            boxShadow: `0 0 50px ${data.bgColor}`,
          }}
        >
          <img src={data.logo} alt="Task Icon" />
        </div>
      )}

      <div className="d-task-content">
        <h3 className="hrad-text">
          {!isHeroSelected ? data.title : data?.characterDetails?.name}
        </h3>
        <NavLink
          text={!isHeroSelected ? btnText : "Show Hero"}
          link={data.buttonLink}
          rightIcon
          onClick={onClick}
          target={data.target}
        />
      </div>
    </DcardBox>
  );
};

export default DtaskCard;
